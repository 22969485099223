import React, { Fragment } from 'react'

import { type GatsbyBrowser } from 'gatsby'

// 채널톡 모듈 불러오기
import './src/modules/channel-talk'

// 전역 스타일 불러오기
import './src/styles/global.css'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return <Fragment>{element}</Fragment>
}
